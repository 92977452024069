import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE } from '@theme_color';

const useStyles = makeStyles({
    root: {
        margin: '16px 0',
    },
    shippingContainer: {
        display: 'flex',
        flexDirection: 'column-reverse',
    },
    boxWrapper: {
        // padding: 6,
        // '@media (max-width: 767px )': {
        //     padding: 8,
        // },
        marginBottom: '8px',
    },
    box: {
        height: '100%',
        border: '1px solid #E5E7EB',
        borderRadius: 6,
        backgroundColor: WHITE,
        padding: 16,
        cursor: 'pointer',
        '& .checkIcon': {
            fontSize: 18,
            margin: '0 4px -4px 0',
            color: '#12B200',
        },
        '&:hover': {
            border: '2px solid #2E7BBF',
            backgroundColor: WHITE,
            color: '#2E7BBF',
            '& .checkIcon': {
                fontSize: 18,
                margin: '0 4px -4px 0',
                color: '#12B200',
            },
            '& .vendorAvailable': {
                color: '#000000',
            },
        },
        '&.selected': {
            border: '2px solid #2E7BBF',
            backgroundColor: WHITE,
            color: '#2E7BBF',
            '& .checkIcon': {
                fontSize: 18,
                margin: '0 4px -4px 0',
                color: '#12B200',
            },
            '& .selectedWrapper': {
                color: '#757575',
            },
            '& .vendorAvailable': {
                color: '#000000',
            },
        },
        '& > span': {
            padding: 0,
        },
        position: 'relative',
        display: 'flex',
        alignItems: 'baseline',
        columnGap: '10px',
        // paddingBottom: 48,

    },
    top: {
        display: 'flex',
    },
    topDesc: {
        marginLeft: 12,
    },
    shippingMethod: {
        fontWeight: 'bold',
        fontSize: '18px',
    },
    vendorName: {
        marginTop: 2,
        fontSize: 12,
    },
    available: {
        margin: '0px',
        '& span': {
            margin: 0,
        },
        '& button:hover': {
            boxShadow: '0px 4px 10px rgba(0, 123, 255, 0.3)',
            backgroundColor: 'rgba(0, 123, 255, 0.1)',
        },
    },
    button: {
        border: 0,
        cursor: 'pointer',
        fontWeight: 500,
        padding: 0,
        marginLeft: '6px',
        color: '#2E7BBF',
        '@media (max-width: 767px )': {
            marginLeft: '0px',
        },
        textTransform: 'capitalize',
    },
    buttonSelected: {
        border: 0,
        cursor: 'pointer',
        fontWeight: 600,
        padding: 0,
        marginLeft: '6px',
        color: '#2E7BBF',
        '@media (max-width: 767px )': {
            marginLeft: '0px',
        },
        textTransform: 'capitalize',
    },
    imgContainer: {
        marginTop: 5,
        height: 36,
        '& img': {
            height: '20px',
            width: '20px',
        },
    },
});

export default useStyles;
