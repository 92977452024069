/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@common_typography';
import Radio from '@material-ui/core/Radio';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useStyles from './style';

const ShippingMethodAndStore = (props) => {
    const {
        t,
        product,
        STORE_PICKUP,
        DELIVERY,
        selectedVendor,
        setSelectedVendorById,
        selectedShippingMethod,
        setSelectedShippingMethod,
        availableSource,
        StoresDialog,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const getBoxClass = (value) => (value === selectedShippingMethod ? 'selected' : '');
    const getAvailableSourceNumber = () => (availableSource ? availableSource.length : 0);

    return (
        <div className={classes.root}>
            <StoresDialog
                open={open}
                onClose={() => setOpen(false)}
                isLayananInstalasi={product?.layanan_instalasi}
                availableSource={availableSource}
                t={t}
                setSelectedVendorById={setSelectedVendorById}
            />
            {/* <div>{product?.layanan_instalasi ? t('product:selectShippingAndStoreLayanan') : t('product:selectShippingAndStore')}</div> */}
            <div className={classNames('row', classes.shippingContainer)}>
                {!product?.layanan_instalasi && (
                    <div className={classNames('col-xs-12 col-sm-12', classes.boxWrapper)}>
                        <div
                            className={classNames(classes.box, getBoxClass(STORE_PICKUP))}
                            onClick={() => setSelectedShippingMethod(STORE_PICKUP)}
                        >
                            <Radio color="default" size="small" checked={!!getBoxClass(STORE_PICKUP)} />
                            {
                                getBoxClass(STORE_PICKUP) ? (
                                    <input
                                        className="mitra10_geolocation_store"
                                        style={{ display: 'none' }}
                                        type="text"
                                        value={selectedVendor?.label || ''}
                                    />
                                ) : null
                            }
                            <div className={classes.top}>
                                <div className={classes.imgContainer}>
                                    {/* {selectedShippingMethod == STORE_PICKUP
                                        ? <img src="/assets/img/pdp/store_pickup_white.svg" alt="" />

                                        : <img src="/assets/img/store_pickup.png" alt="" />} */}
                                    <img alt="wishlist" src="/assets/img/pdp/pickup.svg" />
                                </div>
                                <div className={classes.topDesc}>
                                    <div className={classes.shippingMethod}>
                                        {t('product:clickAndCollect')}
                                    </div>
                                    {/* <div className={classNames('selectedWrapper', classes.vendorName)}>
                                        {selectedVendor && selectedVendor.label}
                                    </div> */}
                                    <div className={classes.available}>
                                        {/* <CheckCircleIcon className="checkIcon" /> */}
                                        <Typography
                                            className="vendorAvailable"
                                            variant="span"
                                            align="center"
                                            color="black"
                                            size="12"
                                        >
                                            {t('product:deliveryLocation')}
                                        </Typography>
                                        <Button
                                            className={getBoxClass(STORE_PICKUP) ? classes.buttonSelected : classes.button}
                                            variant="outlined"
                                            onClick={() => setOpen(true)}
                                            endIcon={<ArrowDropDownIcon className="arrow" fontSize="medium" style={{ color: '#2E7BBF' }} />}
                                        >
                                            {
                                                getBoxClass(STORE_PICKUP)
                                                    ? selectedVendor?.label?.toLowerCase()
                                                    : `${getAvailableSourceNumber()} ${t('product:availableForClickAndCollect')}`
                                            }
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={classNames('col-xs-12 col-sm-12', classes.boxWrapper)}>
                    <div
                        className={classNames(classes.box, getBoxClass(DELIVERY))}
                        onClick={() => setSelectedShippingMethod(DELIVERY)}
                    >
                        <Radio color="default" size="small" checked={!!getBoxClass(DELIVERY)} />
                        {
                            getBoxClass(DELIVERY) ? (
                                <input
                                    className="mitra10_geolocation_store"
                                    style={{ display: 'none' }}
                                    type="text"
                                    value={selectedVendor?.label || ''}
                                />
                            ) : null
                        }
                        <div className={classes.top}>
                            <div className={classes.imgContainer}>
                                {/* {selectedShippingMethod === DELIVERY
                                    ? <img src="/assets/img/pdp/truck_active.png" alt="" />
                                    : <img src="/assets/img/pdp/truck.svg" alt="" /> } */}
                                <img alt="wishlist" src="/assets/img/pdp/delivery.svg" />
                            </div>
                            <div className={classes.topDesc}>
                                <div className={classes.shippingMethod}>
                                    {product?.layanan_instalasi ? t('product:deliveryToHomeLayanan') : t('product:deliveryToHome')}
                                </div>
                                {/* <div className={classNames('selectedWrapper', classes.vendorName)}>
                                    {selectedVendor && selectedVendor.label}
                                </div> */}
                                <div className={classes.available}>
                                    {/* <CheckCircleIcon className="checkIcon" /> */}
                                    <Typography className="vendorAvailable" variant="span" align="center" color="black" size="12">
                                        {t('product:deliveryLocation')}
                                    </Typography>
                                    <Button
                                        className={getBoxClass(DELIVERY) ? classes.buttonSelected : classes.button}
                                        variant="outlined"
                                        onClick={() => setOpen(true)}
                                        endIcon={<ArrowDropDownIcon className="arrow" fontSize="medium" style={{ color: '#2E7BBF' }} />}
                                    >
                                        {
                                            getBoxClass(DELIVERY)
                                                ? selectedVendor?.label?.toLowerCase()
                                                : `${getAvailableSourceNumber()} ${product?.layanan_instalasi
                                                    ? t('product:availableForDeliveryLayanan')
                                                    : t('product:availableForDelivery')}`
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShippingMethodAndStore;
