import { makeStyles } from '@material-ui/core/styles';
import { CreateMargin } from '@theme_mixins';

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        height: '100%',
        maxHeight: 100,
        ...CreateMargin(0, 0, 16, 0),
    },
    label: {
        textTransform: 'capitalize',
    },
    labelDisable: {
        textTransform: 'capitalize',
        color: 'gray !important',
    },
    required: {
        color: 'red',
    },
    disable: {
        '& .MuiInputLabel-formControl': {
            color: 'gray',
        },
    },
    inputDisable: {
        border: '1px solid gray !important',
    },
    empty: {
        color: '#D1D5DB',
        '& .MuiInputBase-input': {
            color: '#D1D5DB',
        },
    },
}));

export default useStyles;
