/* eslint-disable radix */
import { getHost } from '@helper_config';
import { StripHtmlTags } from '@helper_text';
import { modules } from '@config';

const generate = (product) => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    today = `${yyyy}-${mm}-${parseInt(dd) + 1}`;

    const image = [];
    if (product.media_gallery.length > 0) {
        const arrayGallery = [...product.media_gallery];
        const sortedMediaGallery = arrayGallery.sort((a, b) => a.position - b.position);
        // eslint-disable-next-line array-callback-return
        sortedMediaGallery.map((media) => {
            image.push(media.url_original);
        });
    } else {
        image.push(product.image.url_original);
    }

    const schema = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: product.name,
        image,
        description: StripHtmlTags(product.description.html),
        sku: product.sku,
        gtin: product.id,
        gtin12: product.id,
        gtin13: product.id,
        gtin14: product.id,
        gtin8: product.id,
        mpn: product.id,
        brand: modules.brands.enabled ? product.brand : '',
        offers: {
            '@type': 'Offer',
            priceCurrency: product.price_range.minimum_price.final_price.currency,
            url: `${getHost()}/${product.url_key}`,
            availability: 'https://schema.org/InStock',
            price: product.price_range.minimum_price.final_price.value,
            priceValidUntil: today,
        },
    };

    if (product.review.rating_summary) {
        schema.aggregateRating = { // sementara di comment soalnya datanya belum
            '@type': 'AggregateRating',
            bestRating: product.review.rating_summary ? product.review.rating_summary : 1,
            ratingCount: product.review.reviews_count ? product.review.reviews_count : 1,
            ratingValue: product.review.rating_summary ? product.review.rating_summary : 1,
        };
    }

    const schemas = [schema];

    if (product.link_video) {
        const getYouTubeThumbnail = (videoUrl) => {
            const videoId = videoUrl.split('/embed/')[1]?.split('?')[0]; // get ID Video
            return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : null;
        };

        const videoUrl = product.link_video;
        const tempThumbnailUrl = getYouTubeThumbnail(videoUrl);

        if (tempThumbnailUrl) {
            const schemaVideo = {
                '@context': 'https://schema.org/',
                '@type': 'VideoObject',
                name: product.name,
                description: StripHtmlTags(product.short_description?.html || ''),
                thumbnailUrl: tempThumbnailUrl,
                uploadDate: product.updated_at ? product.updated_at.split(' ')[0] : null,
                embedUrl: videoUrl,
            };

            schemas.push(schemaVideo);
        }
    }

    return schemas;
};

export default generate;
