import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_PRIMARY, PRIMARY, WHITE, GRAY_SECONDARY, BLUE_LIGHT,
} from '@theme_color';
import {
    CenterAbsolute, CreateBorder, CreateMargin, CreatePadding, FlexColumn, FlexRow, Centering,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        // margin: '0 !important',
        margin: '0 auto',
        width: '100%',
        height: '100%',
        maxWidth: 1258,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            ...FlexColumn,
        },
        position: 'relative',
    },
    headContainer: {
        position: 'relative',
        backgroundColor: WHITE,
        width: '100%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
        [theme.breakpoints.up('sm')]: {
            '& .slick-slider': {
                maxHeight: 640,
            },
            '& .slick-slide img': {
                maxHeight: 640,
                width: 640,
            },
            paddingRight: '40px',
        },
        '& > div:first-child > div:first-child': {
            overflow: 'auto',
            maxHeight: 640,
        },
    },
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 3,
    },

    body: {
        ...CreatePadding(0, 0, 0, 0),
        width: '100%',
        ...FlexColumn,
        '@media (max-width: 767px )': {
            ...CreatePadding(15, 0, 30, 0),
        },
    },

    footer: {
        ...FlexRow,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 55,
        left: 0,
        ...CenterAbsolute,
        background: 'rgba(255,255,255,1)',
        // opacity : 0.7,
        ...CreatePadding(20, 20, 20, 20),
        zIndex: theme.zIndex.drawer + 1,
    },

    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 30,
        },
        fontWeight: 500,
        '@media (max-width: 767px )': {
            fontSize: '20px',
            lineHeight: '26px',
            marginBottom: '10px !important',
        },
    },

    btnAddToCard: {
        width: '90%',
        [theme.breakpoints.up('sm')]: {
            width: 316,
        },
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 100,
        backgroundColor: BLUE_LIGHT,
    },

    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },

    titleContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    titlePriceContainer: {
        ...FlexColumn,
        flex: 1,
        fontSize: 20,
        '& .price_text': {
            fontSize: 24,
        },
        '& .price__old': {
            fontSize: 16,
            color: '#9CA3AF',
            display: 'block',
        },
        '& .price__final': {
            fontSize: 24,
            fontWeight: 600,
            display: 'block',
            lineHeight: '32px',
        },
        '& .priceShowDisccount': {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'row !important',
            '@media (max-width: 767px )': {
                '& .price__old, .price__final': {
                    fontSize: 10,
                },
            },
            '& > div:first-child': {
                display: 'flex',
                flexDirection: 'column-reverse',
            },
        },
        '& .badge__container': {
            position: 'relative',
            top: 0,
            borderRadius: '50px',
            textAlign: 'center',
            height: '58px !important',
            width: '58px !important',
        },
        '& .badge__value': {
            lineHeight: '57px !important',
            color: '#fff',
            fontSize: '14px !important',
        },
        [theme.breakpoints.down('sm')]: {
            '& .price_text': {
                fontSize: 18,
            },
        },
        '@media (max-width: 767px )': {
            '& .price__old': {
                fontSize: '15px !important',
            },
            '& .price__final': {
                fontSize: '20px !important',
            },
            '& .badge__container': {
                top: '50%',
                transform: 'translateY(-50%)',
                width: '35px',
                height: '35px',
                position: 'absolute',
                right: 0,
            },
            '& .badge__value': {
                fontSize: '14px !important',
                lineHeight: '57px !important',
            },
        },
    },
    priceContent: {
        '@media (max-width: 767px )': {
            width: '100%',
        },
    },
    additionalDiscountContainer: {
        flexDirection: 'column',
    },
    additionalDiscountContent: {
        display: 'flex',
    },
    additionalSign: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        color: 'rgb(243, 46, 59)',
        fontWeight: 'bold',
    },
    additionalDiscountValue: {
        padding: '3px 12px',
        borderRadius: '5px',
        marginTop: '8px',
        fontSize: '16px',
        color: 'white',
        fontWeight: 'bold',
        background: '#D72C0D',
    },
    onlineOnly: {
        margin: '0',
        marginTop: '12px',
        fontSize: '14px',
        fontWeight: '500',
    },
    additionalDiscountAmountValue: {
        marginLeft: '12px',
        width: '128px',
        height: '58px',
        fontSize: '12px',
        color: 'yellow',
        fontWeight: 'bold',
        backgroundImage: 'url(/assets/img/discountamount.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        '& span': {
            position: 'absolute',
            top: '45%',
            transform: 'translateY(-58%)',
            right: 10,
        },
    },
    shareContainer: {
        ...FlexRow,
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        marginTop: 15,
        '& .desc': {
            fontSize: 14,
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginRight: 24,
            cursor: 'pointer',
        },
        '& .item-share': {
            marginRight: 12,
        },
        '@media (max-width: 767px )': {
            display: 'block',
            position: 'absolute',
            top: '40px',
            right: '-10px',
            '& button span': {
                fontSize: 9,
            },
            '& .desc': {
                fontSize: 12,
                marginRight: 0,
                cursor: 'pointer',
            },
        },
        '@media (min-width: 767px )': {
            marginLeft: '15%',
        },
    },

    shareContainerDesktop: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
        paddingTop: '16px',
        borderTop: '1px solid #E5E7EB',
    },
    socialShareDesktop: {
        display: 'flex',
        alignItems: 'center',
        '& .desc': {
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '20px',
        },
    },
    shareRootContainer: {
        ...CreatePadding(15, 30, 30, 30),
        ...FlexColumn,
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },

    btnShare: {
        margin: '0px !important',
        padding: '10px',
        '@media (max-width: 767px )': {
            padding: '7px 10px',
        },
    },
    btnShareDesktop: {
        margin: 0,
        padding: 0,
        height: '20px',
        marginLeft: '16px',
    },
    shareText: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '20px',
        marginLeft: '4px',
    },
    iconShare: {
        color: PRIMARY,
        fontSize: 25,
        ...CreateMargin(0, 0, 0, 0),
        '@media (max-width: 767px )': {
            fontSize: 20,
        },
    },

    carouselContainer: {
        paddingTop: 40,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 70,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    carouselTitle: {
        marginBottom: '20px',
    },
    desc: {
        ...CreateMargin(0, 0, 0, 0),
        textAlign: 'center',
    },
    fullDesc: {
        marginTop: '16px',
        height: 'auto',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#6B7280',
        '& p:first-child': {
            marginTop: '0px',
        },
        '& h2': {
            fontSize: '14px',
            margin: 0,
        },
        '& span': {
            fontSize: '14px !important',
        },
    },
    shortDescDesktop: {
        marginTop: '16px',
        height: '96px',
        overflow: 'hidden',
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '20px',
        position: 'relative',
        color: '#6B7280',
        '& p:first-child': {
            marginTop: '0px',
        },
        '& > div': {
            fontSize: '14px !important',
        },
        '& h2': {
            fontSize: '14px',
            margin: 0,
        },
        '& span': {
            fontSize: '14px !important',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255, 255, 255, 1))',
        },
    },
    detailShortDescBtn: {
        marginRight: 'auto',
        border: 'none',
        background: '#fff',
        cursor: 'pointer',
        marginTop: '8px',
        '& span': {
            color: '#9CA3AF',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '16px',
        },
    },
    ratingContainer: {
        ...FlexRow,
        '& .rating': {
            ...FlexRow,
            '& .MuiSvgIcon-root': {
                height: '20px',
                width: '20px',
                color: '#FBBF24',
                marginRight: '4px',
            },
        },
        '& .rating-count': {
            margin: 0,
            fontWeight: 500,
            fontSize: '14px',
        },
    },
    bottom20: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: 20,
        },
    },
    btnLoadReview: {
        ...Centering,
        textAlign: 'center',
    },
    textLoadReview: {
        color: `${GRAY_SECONDARY} !important`,
    },
    sku: {
        alignItems: 'center',
        margin: 0,
        '& p': {
            fontSize: 14,
            color: '#667085',
            margin: 0,
        },
        '& .sku-name': {
            color: '#000000',
            fontWeight: 500,
            marginRight: '4px !important',
        },
    },
    divider: {
        margin: '0 12px !important',
        background: '#E5E7EB !important',
    },
    tabs: {
        // paddingTop: '40px',
    },
    shareTitle: {
        marginTop: 20,
        fontSize: 12,
    },
    titleRiviews: {
        fontSize: 20,
    },
    threedyContainer: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '13%',
            '& > div': {
                textAlign: 'right',
            },
            '& div:nth-child(2)': {
                textAlign: 'left',
                paddingLeft: '20px',
            },
        },
    },
    btnThreedy: {
        width: 'fit-content',
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 100,
        backgroundColor: BLUE_LIGHT,
    },
    badgeInfo: {
        backgroundColor: '#fff3cd',
        display: 'inline-block',
        margin: '5px 0',
        padding: '4px 8px',
        textAlign: 'center',
        borderRadius: 6,

        '& p': {
            color: '#856404',
            fontSize: '12px',
        },
    },
    stockContainer: {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center',
    },
    stockName: {
        margin: '0',
        fontSize: '14px',
        fontWeight: '500',
    },
    inStock: {
        color: '#047857',
        fontSize: 14,
        fontWeight: 500,
    },
    inStockIcon: {
        display: 'flex',
        alignItems: 'center',
        '& .checkIcon': {
            fontSize: '16px',
            marginRight: '6px',
        },
        '& .voucher': {
            cursor: 'pointer',
        },
    },
    preOrderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '16px',
        marginTop: '16px',
        borderTop: '1px solid #F3F4F6',

        [theme.breakpoints.down('xs')]: {
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: '15px',
        },

        '& > div': {
            margin: 0,
        },
    },
    preorderPolicyButton: {
        background: 'unset',
        border: '0px',
        lineHeight: '20px',
        height: 'max-content',
        textDecorationLine: 'underline',
        cursor: 'pointer',

        [theme.breakpoints.down('xs')]: {
            padding: 0,

            '& > span': {
                margin: 0,
            },
        },

        '& > span': {
            color: '#2E7BBF',
            fontWeight: 500,
            lineHeight: '20px',
            display: 'block',
        },
    },
    headerPreOrderPolicy: {
        display: 'flex',
        padding: '20px',
        borderBottom: '0.8px solid #D1D5DB',
        '@media (max-width: 767px )': {
            padding: 16,
        },
    },
    titlePreOrderPolicy: {
        fontSize: 20,
        color: '#1D2939',
        fontWeight: 600,
        lineHeight: '28px',
        textAlign: 'center',
        flex: 1,
        margin: 0,
        '@media (max-width: 767px )': {
            fontSize: 16,
            lineHeight: '24px',
        },
    },
    buttonClose: {
        padding: '0px 3px',
    },
    modalPreOrderPolicy: {
        '& .MuiPaper-rounded': {
            borderRadius: '8px',
        },
        '& .MuiDialog-paper': {
            '@media (max-width: 767px )': {
                margin: 16,
            },
        },
    },
    preOrderInfo: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 8px',
        borderRadius: '4px',
        border: '0.8px solid #B45309',
        background: '#FEF3C7',
        color: '#B45309',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
    },
    preOrderTextContainer: {
        padding: '26px',
        '@media (max-width: 767px )': {
            padding: '16px',
        },
    },
    outOfStock: {
        color: '#FF1726',
        fontSize: 14,
        fontWeight: 500,
    },
    reviews: {
        color: '#667085',
        fontSize: 14,
        margin: '0px',
        marginLeft: '6px',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .item-share': {
            padding: '20px',
            backgroundColor: '#ffffff',
        },
        '& button:not(:last-child)': {
            marginRight: '15px',
        },
    },
    btnCancel: {
        marginTop: '20px',
        backgroundColor: '#2E3092',
        borderRadius: 5,
    },
    triRight: {
        textAlign: 'right',
    },
    width100: {
        width: '100%',
        marginRight: 0,
        marginLeft: 0,
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
        marginBottom: '12px',
    },
    noPadding: {
        padding: 0,
    },
    shortDesc: {
        // marginTop: '20px',
        padding: '0 10px',
    },
    iframeWrapper: {
        '& iframe': {
            marginTop: '16px',
            width: '100%',
            height: 'auto',
            minHeight: '320px',
            border: 'none',
        },
    },
    desktopShortDescWrapper: {
        marginBottom: '12px',
    },
    installationWrapper: {
        borderTop: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        paddingTop: '9px',
        paddingBottom: '9px',
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .iconRight': {
            fontSize: '32px',
            color: '#98A2B3',
        },
    },
    installationDesc: {
        margin: 0,
        padding: 0,
        fontSize: '14px',
        color: '#667085',
    },
    installationContent: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
    },
    installationTitle: {
        margin: 0,
        padding: 0,
        fontSize: '16px',
        color: '#1D2939',
    },
    installationLink: {
        paddingLeft: '6px',
        color: '#2E7BBF',
        fontSize: '14px',
        fontWeight: 500,
    },
    voucherIcon: {
        marginLeft: '5px',
        marginRight: '5px',
        cursor: 'pointer',
    },
    preOrderListContainer: {
        margin: 0,
        paddingInlineStart: '18px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        '& p': {
            fontSize: '14px',
            lineHeight: '20px',
        },
    },
    chatButton: {
        padding: 0,
        '& img': {
            width: '58px',
            height: 'auto',
            objectFit: 'cover',
        },
        '@media (max-width: 767px )': {
            marginLeft: '16px',
        },
    },
}));

export default useStyles;
